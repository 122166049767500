import React from "react"
import Layout from "../components/layout"

import { LineChart, LineSeries } from "reaviz"

const rawData = `
12/27/2019	0	0	0
12/29/2019	0	0	0
12/30/2019	10	0	1
12/31/2019	20	10	2
1/1/2020	20	20	3
1/2/2020	30	30	5
1/3/2020	40	35	5
1/4/2020	10	10	5
1/6/2020	50	50	6
1/7/2020	30	40	10
1/8/2020	50	50	10
1/9/2020	0	0	0
1/10/2020	30	30	5
1/11/2020	50	40	10
1/13/2020	40	40	10
1/14/2020	50	60	12
1/15/2020	60	60	12
1/16/2020	60	60	10
1/18/2020	40	40	15
1/20/2020	50	50	15
1/21/2020	70	70	15
1/22/2020	80	80	20
1/23/2020	50	50	16
1/24/2020	0	0	0
1/25/2020	50	50	15
1/27/2020	30	30	10
1/28/2020	50	50	20
1/29/2020	100	100	20
1/30/2020	60	60	20
1/31/2020	70	70	20
2/1/2020	100	100	22
2/3/2020	40	40	10
2/4/2020	60	60	20
2/5/2020	100	100	25
2/6/2020	100	100	25
2/7/2020	100	100	25
2/10/2020	100	100	20
2/11/2020	100	100	20
2/12/2020	100	100	26
2/13/2020	100	100	20
2/14/2020	100	100	26
2/17/2020	100	100	20
2/18/2020	100	100	20
`.split(/\r?\n/).filter(line => line)

const days = rawData.map((line) => {
  const captures = line.match(/(.+)\t+(\d+)\t+(\d+)\t+(\d+)/)
  const struct = {
    date: captures[1],
    squats: parseInt(captures[2]),
    crunches: captures[3],
    pushups: captures[4],
  }
  return struct
})

const squatsSeries = days.map((day) => {
  return ({
    key: new Date(day.date),
    data: day.squats
  })
})

const crunchSeries = days.map((day) => {
  return ({
    key: new Date(day.date),
    data: day.crunches
  })
})

const pushupsSeries = days.map((day) => {
  return ({
    key: new Date(day.date),
    data: day.pushups
  })
})

const chartData = [
  {
    key: "Squats",
    data: squatsSeries
  },
  {
    key: "Crunches",
    data: crunchSeries
  },
  {
    key: "Pushups",
    data: pushupsSeries
  }
]

export default () => (
  <Layout>
    <LineChart
     width={"1000"}
     height={"600"}
     series={<LineSeries
       type="grouped"
       colorScheme="cybertron"
     />}
     data={chartData}
    />
  </Layout>
)
